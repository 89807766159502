<template>
  <b-card-code no-body title="Completed mental health checks: All types &amp; Categories
">
    <div class="m-2">
      <!-- Table Top -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <!-- Per Page -->
        <div cols="6" md="6">
          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-input
              id="input-23"
              v-model="searchQuestionnaire"
              type="text"
              size="sm"
              placeholder="Search questionnaire"
              required
          />
        </div>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <!-- Column: Subcategory -->
      <template #cell(subcategory)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.questionnaire.title }}
        </span>
        <router-link :to="{name: 'questionnaire-submissions', params: {
            id: data.item.id,
            category: data.item.questionnaire.subcategory.questionnairecategory.name,
            questionnaireId: data.item.questionnaire.id
          } }">
          <small><u>View details</u></small>
        </router-link>
      </template>

      <!-- Column: Category -->
      <template #cell(category)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{
            data.item.questionnaire.subcategory.questionnairecategory.name
          }}</span>
        </div>
      </template>

      <!-- Column: Queries -->
      <template #cell(queries)="data">
        <b-badge
          pill
          class="text-capitalize"
          v-if="data.item.attempts.length > 0"
        >
          {{ data.item.attempts.length }}
        </b-badge>
          <b-badge
          pill
          class="text-capitalize"
          v-else
        >
          0
        </b-badge>
      </template>

      <!-- Column: Package -->
      <template #cell(payment_basis)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{
            data.item.payment_basis
          }}</span>
        </div>
      </template>
      
      <!-- Column: Last Change -->
      <template #cell(last_change)="data">
        <span
          class="text-capitalize"
          v-if="data.item.attempts.length > 0"
        >
          <span v-if="data.item.attempts[0].created_at == '0 days ago'">
            Today
          </span>
          <span v-else>
            {{ data.item.attempts[0].created_at }}
          </span>
        </span>
        <span
          class="text-capitalize"
          v-else
        >
        </span>
      </template>
      
      <!-- Column: Purchase date -->
      <template #cell(created_at)="data">
          <span>
            {{ data.item.created_at | classic }}
          </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  props: {
    data: {
      type: Array
    },
  },
  data() {
    return {
      searchQuestionnaire: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "subcategory", label: "MHC type" },
        { key: "category", label: "MHC category" },
        { key: "queries", label: "No. of checks", class: "text-center" },
        { key: "payment_basis", label: "Package" },
        { key: "last_change", label: "Last Check", class: "text-center text-nowrap" },
        { key: "created_at", label: "Purchase date", class: "text-center text-nowrap" }
      ],
    };
  },
  computed: {
    filteredList() {
        let searchTerm = this.searchQuestionnaire;
        let countQuestionnaire = 0

        // Filter by subcategory or category
        return this.data.filter(questionnaire => {
            if(questionnaire.questionnaire.subcategory.name == null) {
              questionnaire.questionnaire.subcategory.name = ""
            }
            
            if(questionnaire.questionnaire.subcategory.questionnairecategory.name == null) {
              questionnaire.questionnaire.subcategory.questionnairecategory.name = ""
            }

            if(questionnaire.questionnaire.subcategory.name.toLowerCase().includes(searchTerm.toLowerCase()) || questionnaire.questionnaire.subcategory.questionnairecategory.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                // Update total rows
                countQuestionnaire++
                this.totalRows = countQuestionnaire
                
                return questionnaire;
            }
        });
    }
  },
  created() {
    // Set the initial number of items
    this.totalRows = this.data.length;
  },
  watch:{
    data() {
      this.totalRows = this.data.length
    },
  }
};
</script>

<style scoped>
  .card-header {
    display: none;
  }
</style>