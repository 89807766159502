<template>
  <section class="pb-4">
    <b-row class="match-height">
      <b-col>
        <b-overlay :show="tableShowOverlay" rounded="sm">
          <questionnaires-list :data="questionnairesList" />
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4" md="6">
        <b-overlay :show="show" rounded="sm">
          <future-reminders :data="data.reminders" title="Upcoming mental health checks" />
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import store from "@/store/index";
import { BRow, BCol, BOverlay, BButton } from "bootstrap-vue";
import FutureReminders from "../../components/my-hub/FutureReminders.vue";
import QuestionnairesList from "../../components/my-hub/QuestionnairesList.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '../../main.js';

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,
    FutureReminders,
    QuestionnairesList,
  },
  computed: {
    show() {
      return store.state.formStoreModule.show;
    },
  },
  data() {
    return {
      data: {
        reminders: {
          reminderData: [],
        },
      },
      questionnairesList: [],
      tableShowOverlay: true,
    };
  },
  methods: {

  },
  mounted() {
    store.commit("formStoreModule/setShow", true);

    // Return analytics data
    this.$http.get("/api/auth/user/reminders")
      .then((res) => {
        if(res != undefined) {  
          // Fill Reminders
          for (let i = 0; i < res.data.latest_reminders.length; i++) {
            let number_of_days_to_add = 0;
            if (res.data.latest_reminders[i].reminder_frequency == "weekly") {
              number_of_days_to_add = 7;
            } else if (
              res.data.latest_reminders[i].reminder_frequency == "monthly"
            ) {
              number_of_days_to_add = 30;
            } else if (
              res.data.latest_reminders[i].reminder_frequency == "quarterly"
            ) {
              number_of_days_to_add = 90;
            } else {
              number_of_days_to_add = 0;
            }

            res.data.latest_reminders[i].created_at = moment(
              res.data.latest_reminders[i].created_at,
              "YYYY-MM-DD HH:mm:ss"
            )
              .add(number_of_days_to_add, "days")
              .format("ddd, MMM DD, YYYY");
          }

          res.data.latest_reminders.sort(function compare(a, b) {
            var dateA = new Date(a.created_at);
            var dateB = new Date(b.created_at);
            return dateA - dateB;
          });

          this.data.reminders.reminderData = res.data.latest_reminders;
        }
    })
    .then((res) => {
        store.commit("formStoreModule/setShow", false);
    })
    .catch((error) => {
        store.commit("formStoreModule/setShow", false);

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
        return;
    });

    //Return questionnaires list
    this.$http.get("/api/auth/questionnaires-enrolled")
      .then((res) => {
        if(res != undefined) {
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].attempts.length > 0) {
                let created_at = moment(
                    res.data[i].attempts[0].created_at,
                    "YYYY-MM-DD HH:mm:ss"
                );
                
                let current_date = moment();
                let final_number = Math.abs(current_date.diff(created_at, "days"));

                if(final_number >= 30) {
                    res.data[i].attempts[0].created_at = moment(res.data[i].attempts[0].created_at).format("ll, HH:mm")
                } else {
                    let day = "days ago";
                    if (final_number == 1) {
                    day = "day ago";
                    }
                    res.data[i].attempts[0].created_at = final_number + " " + day;
                }
                }
            }
  
            this.questionnairesList = res.data;
        }
      })
      .then((res) => {
        this.tableShowOverlay = false;
      })
      .catch((error) => {
        console.log(error);
        this.tableShowOverlay = false;

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
        return;

      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";

.fix-height-for-cols {
  margin-bottom: 2rem!important;
  height: 100%!important;
}

.fix-height-for-cols .card {
  height: 100%;
}
</style>